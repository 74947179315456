import React, {useState} from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from 'styled-components';
import {featured_image, content, content_inner} from '../assets/global';
import PageHeader from '../components/page_header';

const NotFoundPage = ({ data, location }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  function toggleMenu(){
      setMenuOpen(!menuOpen)
  }

  const featuredImage = {
    fluid: data.wpPage.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: data.wpPage.featuredImage?.node?.alt || ``,
  }
    
  return (
    <Layout title = {data.wpPage.seo.title} menuOpen = {menuOpen} toggleMenu = {toggleMenu} fullWidthMenu = {false}>
      <Seo description = {data.wpPage.seo.metaDesc} title = {data.wpPage.seo.title} />
      <FeaturedImage>
      <Image
      fluid={featuredImage.fluid}
      alt={featuredImage.alt}
      style={{height: '100%'}}
      />
      </FeaturedImage>            
      <Content>
          <PageHeader
              title = {data.wpPage.title}
          >
          </PageHeader>
          <ContentInner>
              <h1>Ooops! That Page doesn't exist!</h1>
              <Link to = "/">Head back to our homepage</Link>
          </ContentInner>                    
      </Content>
    </Layout>
  )
}

const Content = styled.div`
    ${content};
`;

const FeaturedImage = styled.div`
    ${featured_image};
`;

const ContentInner = styled.div`
    ${content_inner};

    a{
      text-decoration:none;
      font-family:"Karla", sans-serif;
      font-weight:bold;
    }
`

export const pageQuery = graphql`
{
  wpPage(template: {templateName: {eq: "About Us Template"}}) {
    seo {
      metaDesc
      title
    }
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
}
`

export default NotFoundPage


